//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  name: "ConnectingPage",
  data: () => ({
    isConnected: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      mnemonicData: state => state.substrate.mnemonicData
    })
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialData();
    }
  },
  async created() {
    if (this.mnemonicData) this.initialData();
  },
  mounted() {
    setTimeout(() => {
      this.isConnected = true;
    }, 2400);
  }
};